import Typewriter from 'typewriter-effect/dist/core';

class Header {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-header');
    this.dom.type = this.dom.el.querySelector('.js-type');
  }

  init() {
    const typewriter = new Typewriter(this.dom.type, {
      loop: true,
      delay: 75,
    });

    typewriter
      .typeString('onderhoud')
      .pauseFor(2000)
      .deleteChars(9)
      .typeString('reparatie')
      .pauseFor(2000)
      .deleteChars(9)
      .typeString('innovatie')
      .pauseFor(2000)
      .deleteChars(9)
      .start();
  }
}

export default Header;
