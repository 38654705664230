class Cta {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-cta');
    this.dom.sibling = this.dom.el.previousElementSibling;
  }

  sibling = () => {
    this.dom.sibling.style.marginBottom = '0';

    if (this.dom.el.nextElementSibling) {
      this.dom.el.classList.add('has-sibling');
    }
  }

  init() {
    window.addEventListener('load', this.sibling);
  }
}

export default Cta;
