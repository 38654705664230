import Headroom from 'headroom.js';

class Hamburger {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-hamburger');
    this.dom.headroom = new Headroom(this.dom.el, {
      // offset: 100,
    });
  }

  init() {
    this.dom.headroom.init();
  }
}

export default Hamburger;
