class Nav {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-nav');
    this.dom.app = document.querySelector('.js-app');
    this.dom.hamburger = this.dom.el.querySelector('.js-hamburger');
  }

  toggleMenu = () => {
    this.dom.app.classList.toggle('nav-open');
  }

  init() {
    this.dom.hamburger.addEventListener('click', this.toggleMenu);
  }
}

export default Nav;
