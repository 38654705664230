// import styles
import './index.scss';

// global js
import Header from './components/header/header.js';
import Hamburger from './components/hamburger/hamburger.js';
import Nav from './components/nav/nav.js';
import Cta from './components/cta/cta.js';
// import Testimonials from './components/testimonials/testimonials.js';

let HeaderEl = document.querySelector('.js-header');
if (HeaderEl) {
  const header = new Header();
  header.init();
}

let HamburgerEl = document.querySelector('.js-hamburger');
if (HamburgerEl) {
  const hamburger = new Hamburger();
  hamburger.init();
}

let NavEl = document.querySelector('.js-nav');
if (NavEl) {
  const nav = new Nav();
  nav.init();
}

let ctaEls = document.querySelectorAll('.js-cta');
[...ctaEls].forEach(el => {
  const cta = new Cta(el);
  cta.init();
});
